<template>

  <div>

    <section class="about-top">
        <h1>事業内容</h1>
        <p>
          web制作のほか、制作会社さまの<br class="sp">外部パートナーとして<br class="pc">
          事業のお手伝いを<br class="sp">させていただいています。<br>
          一つの技術に固執せず、お客さまの<br class="sp">ご要望や予算感により<br class="pc">
          柔軟に対応いたします。
        </p>
    </section>

    <section class="about-onecode">
        <h2>one codeの概要</h2>
        <dl>
          <div v-for="(about, index) in abouts" :key="index">
            <dt>{{ about.title }}</dt>
            <dd>{{ about.text }}</dd>
          </div>
          <div>
            <dt>連絡先アドレス</dt>
            <dd>
              info(at-mark)onecode-web.com
              <span>※ (at-mark)を@に置き換えてください。</span>
            </dd>
          </div>
        </dl>
    </section>

    <section class="about-service target">
      <h2>サービス内容</h2>
      <dl>
        <div v-for="(service, index) in services" :key="index">
          <dt>{{ service.title }}</dt>
          <dd>{{ service.text }}</dd>
        </div>
      </dl>
    </section>

    <section class="about-concept">
      <h2>大切にしていること</h2>
      <dl>
        <div v-for="(concept, index) in concepts" :key="index" class="target">
          <dt>{{ concept.title }}</dt>
          <dd>{{ concept.text }}</dd>
        </div>
      </dl>
    </section>

    <section class="about-profile">
      <h2>自己紹介</h2>
      <div>
        <div>
          <img src="../assets/image/image/tanaka.jpg" alt="自己紹介">
        </div>
        <p class="target">
          はじめまして、田中 杏と申します。<br>
          2020年まで製薬会社に勤めていましたが、新しいことをしたいと思いプログラミングを始め、2021年5月にSAMURAI ENGINEERを卒業しました。<br>
          <br>
          私は、毎回の仕事で経験を積み、成長しつづけることをお約束します。
        </p>
      </div>
    </section>

    <Contact />
    
  </div>

</template>

<script>
import { onMounted } from 'vue'

import Observer from '../utils/observer'
import Contact from '../components/ContactLink'

export default {

  components: {
    Contact
  },

  setup() {
    onMounted(() => {
      Observer();
    })

    const abouts = [
      {
        title: '屋号名', text: 'one code（ワンコード）'
      },
      {
        title: '事業形態', text: '個人事業'
      },
      {
        title: '活動開始日', text: '2021 年 5 月 10日'
      },
      {
        title: '所在地', text: "茨城県"
      },
    ];

    const services = [
      {
        title: '提供サービス', text: 'webサイト制作・更新・運用 / WordPress構築・カスタマイズ / 設計・デザイン / 動画編集'
      },
      {
        title: '開発スキル', text: 'HTML / CSS・Sass / JavaScript・React・JQuery・Vue.js / PHP'
      },
      {
        title: '開発ツール', text: 'Visual Studio Code / Figma / Adobe Photoshop / DaVinci Resolve / GitHub / gulp'
      },
    ];

    const concepts = [
      {
        title: 'スピード感を重視',
        text: '1人だからこそ、スピード感を持って制作します。誰かの処理待ちなどがないので、1つずつ集中して納期に余裕を持ったスケジュールで作業いたします。'
      },
      {
        title: 'ユーザーが迷わないサイト構成',
        text: 'webサイト制作において、『わかりやすさ』が重要だと考えております。ボタンはわかりやすい位置にあるか、コンテンツの流れは正しいかなど、サイトにある全ての要素に意味を持たせ、ユーザー視点に立った設計・デザインを大切にします。'
      },
      {
        title: 'コードを正しく、わかりやすく',
        text: 'サイトの見た目だけでなく、一つ一つのコードにもこだわっています。正しいコードをわかりやすく書くことで、メンテナンス性も上がりSEO対策にもつながります。'
      },
      {
        title: '最適な技術を提案',
        text: 'お客さまのご要望や予算感に応じて、新旧を問わず技術を選択・提案することが可能です。そして今後も新しい技術を学び続けます。'
      },
    ]

    return { abouts, services, concepts }
  },
}
</script>

<style lang="scss">

.about-top {
  @include lowerpage-title('ABOUT');
}

.about-onecode {
  position: relative;
  width: 100%;
  max-width: 1180px;
  padding: 0 50px;
  margin: 85px auto 0;

    h2 {
      @include lowersection-title('ABOUT');
    }

    dl {
      width: 100%;
      max-width: 870px;
      margin: 40px auto 0;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;
        border-bottom: 1px solid $gray;

        dt {
          width: 140px;
          margin: 0 0 10px 0;
          font-family: 'Noto Sans JP_b';
          font-weight: 800;
        }

        dd {
          width: calc(100% - 240px);
          text-align: left;
          line-height: 1.5;

          span {
            display: block;
            margin: 5px 0 0 0;
            font-size: 14px;
          }
        }
      }
    }
}

.about-service.target {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1180px;
  margin: 170px auto 0;
  padding: 0 50px;
  background-color: $white;

  h2 {
    @include lowersection-title('SERVICE');
  }

  dl {
    transform: translateY(50px);
    width: 100%;
    max-width: 870px;
    margin: 40px auto 0;
    opacity: 0;
    transition: .5s;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;
      border-bottom: 1px solid $gray;

      dt {
        width: 140px;
        margin: 0 0 10px 0;
        font-family: 'Noto Sans JP_b';
        font-weight: 800;
        line-height: 1.5;
      }

      dd {
        width: calc(100% - 240px);
        text-align: left;
        line-height: 1.5;
      }
    }
  }

  &.show {
    dl {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.about-concept {
  width: 100%;
  max-width: 1180px;
  margin: 170px auto 0;
  padding: 0 50px;

  h2 {
    @include lowersection-title('CONCEPT');
  }

  dl {
    width: 100%;
    max-width: 870px;
    margin: 70px auto 0;

    div.target {
      position: relative;
      transform: translateY(50px);
      padding: 0 0 0 110px;
      opacity: 0;
      transition: .5s;

      dt {
        margin: 0 0 13px 0;
        font-family: 'Noto Sans JP_b';
        font-weight: 800;
      }

      dd {
        line-height: 1.5;
      }

      & + div {
        margin: 70px 0 0 0;
      }

      @for $i from 1 through 4 {
        &:nth-of-type(#{$i})::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 65px;
          height: 65px;
          background: no-repeat url(../assets/image/icon/concept0#{$i}.svg);
          background-size: 100%;
          content: '';
        }
      }

      &.show {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.about-profile {
  width: 100%;
  max-width: 1080px;
  margin: 170px auto 0;
  padding: 0 50px;

  h2 {
    @include lowersection-title('PROFILE');
  }

  > div {
    display: flex;
    justify-content: space-between;
    margin: 60px 0 0 0;
    padding: 0 50px;

    div {
      width: 40%;
      max-width: 400px;
      margin: 0 20px 0 0;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    p.target {
      transform: translateY(50px);
      width: 55%;
      max-width: 450px;
      line-height: 1.5;
      opacity: 0;
      transition: .5s;

      &.show {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 1082px) {
    div {
      img {
        margin: 0 20px 0 0;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .about-top {
    margin: 140px auto 0;
  }

  .about-onecode {
    padding: 0 20px;
    margin: 75px 0 0 0;

    dl {
      flex-direction: column;
      align-items: flex-start;
      margin: 25px 0 0 0;
      padding: 0;

      div {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0;

        dd {
          width: 100%;
        }
      }
    }
  }

  .about-service.target {
    width: 100%;
    margin: 75px 0 0 0;
    padding: 0 20px;
    box-shadow: unset;

    dl {
      margin: 25px 0 0 0;

      div {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0;

        dd {
          width: 100%;
        }
      }
    }
  }

  .about-concept {
    margin: 75px 0 0 0;
    padding: 0 20px;

    dl {
      margin: 50px 0 0 0;
      padding: 0;

      div.target {
        padding: 0;

        dt {
          position: relative;
          padding: 0 0 0 60px;
        }

        dd {
          margin: 30px 0 0 0;
          line-height: 1.7;
        }

        @for $i from 1 through 4 {
          &:nth-of-type(#{$i})::before {
            content: unset;
          }

          &:nth-of-type(#{$i}) {
            dt::before {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 45px;
              height: 45px;
              background: no-repeat url(../assets/image/icon/concept0#{$i}.svg);
              background-size: 100%;
              content: '';
            }
          }
        }

        & + div {
          margin: 45px 0 0 0;
        }
      }
    }
  }

  .about-profile {
    margin: 75px 0 0 0;
    padding: 0;

    h2 {
      margin: 0 20px;
    }

    > div {
      flex-direction: column;
      padding: 0;

      div {
        width: calc(100% - 20px);
        max-width: unset;
        height: 200px;

        img {
          width: 100%;
          height: 100%;
          object-position: center 30%;
        }
      }

      p.target {
        width: 100%;
        margin: 30px 0 0 0;
        padding: 0 20px;
      }
    }
  }
}

</style>