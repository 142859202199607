<template>
  
  <section>
    <h1>お問い合わせ、ありがとうございます</h1>
    <p>
      メール送信が完了しました。<br>
      確認次第、早急にご連絡いたします。<br>
      お問い合わせ内容によっては、返信いたしかねますので<br>
      ご了承ください。
    </p>
    <router-link :to="{ name: 'Home' }">
      トップへ戻る
    </router-link>
  </section>

</template>

<script>
import { inject, onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const store = inject('store')
    const router = useRouter()

    onMounted(() => {
      if(!store.state.contact) {
        router.push('/')
      }
    })
  }
  
}
</script>

<style lang="scss" scoped>
section {
  @include lowerpage-title('CONTACT');

  a {
    display: block;
    width: 180px;
    margin: 100px auto 0;
    color: $white;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 30px 13px;
    background-color: $green;
    border: 2px solid $green;
    border-radius: 20px;
    transition: .3s;

    &:hover {
      background-color: $white;
      color: $green;
    }
  }
}
@media screen and (max-width: 767px) {
  section {
    margin: 80px auto 0;
    
    a {
      font-size: 14px;
    }
  }
}
</style>