<template>

  <div>

    <section class="index-top">
      <div :class="{ 'active': mount.state }">
        <span>web production</span>
        <p class="index-top-text02">
          思いやこだわりを<br />
          カタチにします。
        </p>
        <h1>
          webサイト制作をしている、<br class="sp">茨城のフリーランス
        </h1>
      </div>
      <div>
        <video
          src="../assets/image/video/onecode1.mp4"
          autoplay
          muted
          loop
          playsinline
        />
      </div>
      <div class="index-top02" :class="{ 'active': mount.state }">
        <span>SCROLL</span>
      </div>
    </section>

    <section class="index-news" :class="{ 'active': mount.state }">
      <h2>NEWS</h2>
      <dl>
        <Carusel  v-slot="{ currentSlide }">
          <div 
            v-for="(item, index) in items" :key="index" 
            class="news" :class="[{ 'show': currentSlide === index + 1 }, { 'new': currentSlide === 1}]"
          >
            <dt>{{ item.title }}</dt>
            <dd>
              {{ item.text }}
            </dd>
          </div>
        </Carusel>
      </dl>
    </section>

    <section class="index-works">
      <h2>
        実績紹介
      </h2>

      <swiper
        :slides-per-view="2.1"
        :navigation="{ nextEl: '.nextArrow', prevEl: '.prevArrow' }"
        :breakpoints="swiperOptions.breakpoints"
        class="target"
      >
        <div class="index-works01">
          <button class="prevArrow">
            <img src="../assets/image/icon/slide-arrow-l.svg" alt=">">
          </button>
          <button class="nextArrow">
            <img src="../assets/image/icon/slide-arrow-r.svg" alt="<">
          </button>
        </div>
        <swiper-slide v-for="(work, index) in works" :key="index">
          <img :src="work.src" :alt="work.alt">
          <time :datetime="work.datetime">{{ work.time }}</time>
          <p>{{ work.title }}</p>
          <dl>
            <dt>制作内容</dt>
            <dd>{{ work.contain }}</dd>
          </dl>
        </swiper-slide>
      </swiper>
    </section>

    <section class="index-about">
      <div>
        <h2>one codeの特徴</h2>
        <dl class="target">
          <dt>こんな課題を解決できます。</dt>
          <dd>分かりやすいwebサイトを作りたい</dd>
          <dd>デザインから実装まで頼みたい</dd>
          <dd>部分的に頼めるパートナーが欲しい</dd>
        </dl>
      </div>
      <div>
        <img src="../assets/image/image/about.png" alt="one codeの特徴">
        <video
          src="../assets/image/video/about.mp4"
          autoplay
          muted
          loop
          playsinline
        />
      </div>
      <dl class="target">
        <div v-for="(feature, index) in features" :key="index">
          <dt>
            <span>{{ feature.num }}</span>
            {{ feature.title }}
          </dt>
          <dd>{{ feature.text }}</dd>
        </div>
      </dl>
      <router-link :to="{ name: 'About' }">
        事業内容
      </router-link>
    </section>

    <section class="index-contact">
      <h2>webサイト制作を承ります</h2>
      <p>
        webサイトの新規作成やリニューアルなど、<br>
        幅広くお受けしております。<br>
      </p>
      <p>
        また、web制作のほか<br>
        制作会社さまの外部パートナーとして<br>
        事業のお手伝いをさせていただいています。<br>
      </p>
      <p>
        <span>info(at-mark)onecode-web.com</span>
        <span>※ (at-mark)を@に置き換えてください。</span>
      </p>
      <router-link :to="{ name: 'Contact' }">
        お問い合わせ
      </router-link>
    </section>

  </div>
</template>

<script>
import { onMounted, reactive } from 'vue'

import { Swiper, SwiperSlide,  } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper/core'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import Observer from '../utils/observer'

import Carusel from '../components/Carousel.vue'

SwiperCore.use( Navigation );

export default {
  
  components: {
    Swiper,
    SwiperSlide,
    Carusel,
  },

  setup() {
    const mount = reactive({
      state: false
    })
    onMounted(() => {
      mount.state = true;
      Observer();
    })

    const swiperOptions = {
      breakpoints: {
        767: {
          slidesPerView: 2.5
        }
      }
    }

    const works = [
      {
        src: require('@/assets/image/image/in-production.jpg'), 
        alt: '制作中', 
        time: '2021.08', 
        datetime: '2021-08',
        title: '個人事業のランディングページ', 
        contain: '設計\nデザイン\nロゴ制作'
      },
      {
        src: require('@/assets/image/image/in-preparation.jpg'), 
        alt: '準備中', 
        time: '2021.08', 
        datetime: '2021-08',
        title: 'IT会社のコーポレートサイト', 
        contain: "設計\nデザイン\nコーディング（React）\nWrodPressオリジナルテーマ構築"
      },
      {
        src: require('@/assets/image/image/onecode_img.jpg'), 
        alt: 'one code', 
        time: '2021.08', 
        datetime: '2021-08',
        title: 'one code', 
        contain: '設計\nデザイン\nコーディング（Vue.js）'
      },

    ];

    const features = [
      {
        num: '01',
        title: 'ヒアリングから納品まで',
        text: '製作者がお客さまと直接コミュニケーションをとるので、より理想に近いwebサイトを作成できます。'
      },
      {
        num: '02',
        title: 'リアルタイムで確認可能',
        text: '設計書やデザインはクラウド上で管理します。常に、最新状態でご確認いただけます。'
      },
      {
        num: '03',
        title: '必要な業務に応じて柔軟に対応',
        text: '様々な業務に柔軟に対応いたします。デザインのみ・コーディングのみのご依頼も可能です。'
      },
      {
        num: '04',
        title: '動画編集も対応',
        text: 'webサイトに動画を取り入れたい場合などは、動画の編集から対応いたします。'
      },
    ]

    const items = [
      {
        title: '2021.08',
        text: 'ホームページを公開しました。',
      },
      {
        title: '2021.05',
        text: 'フリーでの活動を開始しました。',
      }
    ]

    let currentItem = 0
    const countup = setInterval(() => {
      currentItem++
    if(currentItem > items.length) {
      clearInterval(countup)
    }
      }, 1000);

    const isActive = (index) => {
      console.log(index)
    }

    return { mount, works, features, swiperOptions, items, isActive }
  },

}

</script>

<style lang="scss" scoped>

.index-top {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100vh - 320px);
  margin: 0 auto;
  padding: 0 0 0 16.4vw;

    > div:nth-of-type(2) {
      position: relative;
      width: 60%;
      height: 100%;
      margin: 0 0 0 auto;


      video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > div:first-child {

      span {
        display: inline-block;
        transform: translateY(20px);
        padding: 0 0 0 5px;
        font-family: "Josefin Sans", sans-serif;
        opacity: 0;
      }

      p {
        position: relative;
        transform: translateY(20px);
        margin: 10px 0 25px;
        font-size: 45px;
        font-family: 'Noto Sans JP_b';
        font-weight: 800;
        letter-spacing: 0.1em;
        line-height: 1.5;
        opacity: 0;
      }

      h1 {
        transform: translateY(20px);
        display: flex;
        padding: 0 0 0 5px;
        font-size: 16px;
        opacity: 0;
      }

      &.active {
        p {
          animation: .8s loadedAnimation ease-in-out .5s both;
        }

        span {
          animation: .8s loadedAnimation ease-in-out 1.5s both;
        }

        h1 {
          animation: .8s loadedAnimation ease-in-out 1.8s both;
        }

        @keyframes loadedAnimation {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }

  .index-top02 {
    position: absolute;
    bottom: -150px;
    right: 59px;
    display: flex;
    justify-content: flex-end;
    width: 45px;
    height: 125px;
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    transition: .8s;
    transition-delay: 3s;

    span {
      position: relative;
      font-size: 10px;
      font-family: "Josefin Sans", sans-serif;
      writing-mode: vertical-rl;

      &::before {
        position: absolute;
        top: 0;
        left: -10px;
        z-index: 1;
        width: 2px;
        height: 105px;
        background-color: $green;
        content: '';
        animation: scrollDown 2s cubic-bezier(0.55, 0, 0.15, 1) infinite;
      }

      &::after {
        position: absolute;
        top: 0;
        left: -10px;
        width: 1px;
        height: 120px;
        background-color: $gray;
        content: '';
      }

      @keyframes scrollDown {
        0% {
          transform: scale(1, 0);
          transform-origin: 0 0;
        }
        50% {
          transform: scale(1, 1);
          transform-origin: 0 0;
        }
        50.1% {
          transform: scale(1, 1);
          transform-origin: 0 100%;
        }
        100% {
          transform: scale(1, 0);
          transform-origin: 0 100%;
        }
      }
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  @media screen and (max-width: 1365px) {
    padding: 0 0 0 9.4vw;

    > div:first-child {
      p {
        font-size: 40px;
      }
    }
  }

  @media screen and (max-width: 1082px) {
    padding: 0 0 0 50px;

    > div:first-child {
      p {
        font-size: 3.4vw;
      }

      h1 {
        margin: 0 10px 0 0;
      }
    }

    .index-top02 {
      right: 39px;
    }
  }

  // @media screen and (max-width: 1439px) {
  //   display: flex;
  //   align-items: flex-end;
  //   padding: 0 0 100px 0;

  //   > div:first-child {
  //     height: 55%;
  //     margin: 0 0 0 auto;
  //   }

  //   > div:nth-of-type(2) {
  //     top: 60px;
  //     transform: translateY(0);
  //   }
  // }

  // @media screen and (max-width: 1365px) {
  //   > div:nth-of-type(2) {
  //     left: 9.4vw;
  //   }

  //   .index-top02 {
  //     top: unset;
  //     left: 50px;
  //     bottom: 160px;
  //   }
  // }

  // @media screen and (max-width: 1082px) {
  //   > div:first-child {
  //     width: 80%;
  //   }

  //   > div:nth-of-type(2) {
  //     left: 50px;

  //     h1 {
  //       font-size: 45px;
  //     }
  //   }
  // }
}

.index-news {
  width: 100%;
  max-width: 460px;
  margin: 30px 0 0 16.4vw;
  padding: 20px 0 40px;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  opacity: 0;
  visibility: hidden;
  transition: .8s;
  transition-delay: 3s;

  h2 {
    margin: 0 0 10px 0;
    font-family: "Josefin Sans", sans-serif; 
  }

  dl {
    position: relative;
    width: 100%;

    div.news {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(30px);
      transition: 1s cubic-bezier(0.33, 1, 0.68, 1) ;
      display: flex;
      width: 100%;
      max-width: 460px;
      font-size: 14px;
      opacity: 0;
      visibility: hidden;

      &.show {
        transform: translateX(0);
        width: 100%;
        opacity: 1;
        visibility: visible;
        transition-delay: .5s;

        &.new {
          dd::after {
            display: inline-block;
            width: 35px;
            height: 15px;
            line-height: 15px;
            text-align: center;
            vertical-align: bottom;
            background-color: $green;
            color: $white;
            font-size: 12px;
            font-family: "Josefin Sans", sans-serif; 
            content: 'new';
          }
        }
      }

      dt {
        margin: 0 20px 0 0;
        font-weight: 400;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: 1365px) {
    margin: 30px 0 0 9.4vw;
  }

  @media screen and (max-width: 1082px) {
    margin: 30px 0 0 50px;
  }
}

.index-works {
  position: relative;
  margin: 100px 0 0 0;
  padding: 40px 0 0 16.4vw;
  overflow: hidden;

  h2 {
    @include section-title('WORKS');
  }

  .swiper-container.target {
    position: relative;
    transform: translateY(60px);
    padding: 40px 0 0 0;
    overflow: visible;
    opacity: 0;
    transition: all .5s;

    .index-works01 {
      position: absolute;
      top: 0;
      right: 70px;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      width: 80px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid rgba(17, 17, 17, 0.60);
        border-radius: 50%;
        outline: unset;

        img {
          width: 15px;
          height: 15px;
          transition: .3s;
        }

        &.prevArrow:hover {
          img {
            transform: translateX(-5px);
          }
        }

        &.nextArrow:hover {
          img {
            transform: translateX(5px);
          }
        }

        &.swiper-button-disabled {
          opacity: .4;
        }
      }
    }

    .swiper-slide {
      padding: 0 30px 0 0;

      img {
        max-width: 440px;
        width: 100%;
      }

      time {
        display: inline-block;
        margin: 15px 0 0 0;
        font-size: 12px;
        font-weight: 400;
        color: rgba(17, 17, 17, 0.5);
      }

      p {
        margin: 2px 0 10px;
        font-size: 16px;
      }

      dl {
        display: flex;

        dd {
          margin: 0 0 0 30px;
          white-space: pre-wrap;
          line-height: 1.5;
        }
      }
    }

    &.show {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media screen and (max-width: 1365px) {
    padding: 40px 0 0 9.4vw;
  }

  @media screen and (max-width: 1082px) {
    padding: 40px 0 0 50px;
  }
}

.index-about {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 170px auto 0;
  padding: 0 16.4vw;

  > div:nth-of-type(1) {
    width: 100%;
    max-width: 45%;

    h2 {
      @include section-title('ABOUT');
    }

    dl.target {
      transform: translateY(60px);
      margin: 50px 0 0 0;
      opacity: 0;
      transition: .5s;

      dt {
        margin: 0 0 30px 0;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: .1em;
      }

      dd {
        position: relative;
        margin: 20px 0 0 0;
        padding: 0 0 0 45px;

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 26px;
          height: 26px;
          background: no-repeat url(../assets/image/icon/check.svg);
          background-size: 100%;
          content: '';
        }
      }

      &.show {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  > div:nth-of-type(2) {
    position: relative;

    video {
      position: absolute;
      top: 10.10px;
      right: 12.04px;
      width: 406.8px;
      height: 229.5px;
      object-fit: cover;
    }
  }

  > dl.target {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 25px 0 0 0;

    div {
      transform: translateY(50px);
      width: 45%;
      max-width: 510px;
      margin: 60px 0 0 0;
      opacity: 0;

      dt {
        font-size: 18px;
        font-family: 'Noto Sans JP_b';
        font-weight: 800;

        span {
          display: inline-block;
          margin: 0 5px 0 0;
          color: $green;
          font-size: 40px;
          font-family: "Josefin Sans", sans-serif;
          font-weight: bold;
          letter-spacing: .1em;
        }
      }

      dd {
        position: relative;
        margin: 30px 0 0 0;
        padding: 0 0 0 90px;
        line-height: 1.8;
      }

      @for $i from 1 through 4 {
        &:nth-of-type(#{$i}) {
          transition: .5s;
          transition-delay: (0.22s * ($i - 1));

          dd::before {
            position: absolute;
            top: -10px;
            left: 0;
            width: 67px;
            height: 67px;
            background: no-repeat url(../assets/image/icon/about0#{$i}.svg);
            background-size: 100%;
            content: '';
          }
        }
      }
    }

    &.show {
      div {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  a {
    @include to-button($green, $white, 'arrow');
  }

  @media screen and (max-width: 1365px) {
    padding: 0 9.4vw;
  }

  @media screen and (max-width: 1082px) {
    padding: 0 50px;

    > div:nth-of-type(1) {
      max-width: calc(100% - 600px);
      min-width: 350px;
    }


    > dl.target {
      div {
        width: calc((100% - 20px) / 2);
        

        dt {
          span {
            font-size: 34px;
          }
        }

        dd::before {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  @media screen and (max-width: 932px) {
    > div:nth-of-type(2) {
      width: 294px;

      img {
        width: 100%;
      }

      video {
        top: 6.8px;
        right: 7.41px;
        width: 248px;
        height: 139.8px;
      }
    }
  }
}

.index-contact {
  margin: 170px 0 0 0;
  padding: 120px 16.4vw 90px;
  background-color: $green;

  h2 {
    color: $white;
    font-size: 36px;
    font-family: 'Noto Sans JP_b';
    font-weight: 800;
  }

  p {
    color: $white;
    line-height: 1.8;


    span {
      display: block;
      color: $white;
      line-height: 1.3;

      &:nth-of-type(1)::before {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0 20px 0 0;
        background: no-repeat url(../assets/image/icon/email.svg);
        background-size: 100%;
        vertical-align: top;
        content: '';
      }

      &:nth-of-type(2) {
        margin: 0 0 0 44px;
        font-size: 12px;
      }

    }

    &:nth-of-type(1) {
      margin: 50px 0 15px;
    }

    &:nth-of-type(3) {
      margin: 50px 0 0 0;
    }
  }

  a {
    @include to-button($white, $green, 'arrow-w');
  }

  @media screen and (max-width: 1365px) {
    padding: 120px 9.4vw 90px;
  }

  @media screen and (max-width: 1082px) {
    padding: 120px 50px 90px;
  }
}

@media screen and (max-width: 767px) {
  .index-top {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    margin: 80px auto 0;
    padding: 0 0 120px 0;

    > div:nth-of-type(2) {
      width: 100%;
      height: 40%;
      margin: 20px 0 0 0;
    }

    > div:first-child {
      margin: 10% 0 0 0;
      padding: 0 20px;

      p {
        font-size: 24px;
        margin: 15px 0 10px;
        padding: 0;

        &::after {
          content: unset;
        }
      }

      h1 {
        font-size: 14px;
        line-height: 1.5;
      }
    }

    .index-top02 {
      position: unset;
      height: auto;
      background-color: unset;
      margin: 30px auto 0;
      opacity: 1;
      visibility: visible;

      span {
        position: relative;
        writing-mode: unset;

        &::after {
          position: absolute;
          top: 20px;
          left: 50%;
          width: 1px;
          height: 120px;
          background-color: $gray;
          content: '';
        }

        &::before {
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          height: 120px;
          background-color: $green;
        }
      }
    }

    &::after {
      content: unset;
    }
  }

  .index-news {
    max-width: calc(100% - 40px);
    padding: 20px 0 50px;
    margin: 30px 20px 0;

    h2 {
      font-size: 14px;
    }

    dl {
      div.news {
        flex-direction: column;
        max-width: calc(100% - 40px);
        font-size: 12px;

        dt {
          margin: 0 0 5px 0;
        }

        &.new {
          dd::after {
            width: 25px;
            vertical-align: top;
          }
        }
      }
    }
  }

  .index-works {
    margin: 75px 0 0 0;
    padding: 0 0 0 20px;

    .swiper-container.target {
      .index-works01 {
        right: 20px;

        button {
          &.prevArrow:hover {
            img {
              transform: translateX(0);
            }
          }

          &.nextArrow:hover {
            img {
              transform: translateX(0);
            }
          }
        }
      }

      .swiper-slide {
        time {
          font-size: 10px;
        }

        p {
          font-size: 12px;
        }

        dl {
          display: none;
        }
      }
    }
  }

  .index-about {
    margin: 75px 0 0 0;
    padding: 0 20px;
    flex-wrap: nowrap;
    flex-direction: column;

    > div:nth-of-type(1) {
      width: 100%;
      max-width: unset;
      min-width: unset;
      margin: 0 0 50px;

      dl.target {
        dt {
          font-size: 16px;
        }

        dd {
          padding: 0 0 0 20px;
          font-size: 14px;

          &::before {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    > div:nth-of-type(2) {
      margin: 0 auto;
      transform: translateX(-17px);
    }

    > dl.target {
      div {
        max-width: unset;
        width: 100%;
        margin: 35px 0 0 0;

        dt {
          padding: 0 0 0 40px;
          font-size: 16px;

          span {
            display: none;
          }
        }

        dd {
          margin: 20px 0 0 0;
          padding: 0;
          font-size: 14px;

          &:before {
            display: none;
          }
        }

        @for $i from 1 through 4 {
          &:nth-of-type(#{$i}) {
            transition: .5s;
            transition-delay: (0.22s * ($i - 1));
  
            dt::before {
              position: absolute;
              top: -10px;
              left: 0;
              width: 36px;
              height: 36px;
              background: no-repeat url(../assets/image/icon/about0#{$i}.svg);
              background-size: 100%;
              content: '';
            }
          }
        }
      }
    }
  }

  .index-contact {
    margin: 75px 0 0 0;
    padding: 60px 20px 45px;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;

      span {

        &:nth-of-type(1)::before {
          width: 20px;
          height: 20px;
        }

        &:nth-of-type(2) {
          margin: 0 0 0 40px;
          font-size: 10px;
        }
      }
    }
  }
}
</style>