import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Contact from '../views/Contact'
import About from '../views/About'
import Thanks from '../views/Thanks'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  {
    path: '/Contact/Thanks',
    name: 'Thanks',
    component: Thanks,
  }
]

const scrollBehavior = (to, from, savePosition) => {

  if(savePosition) {
    return savePosition
  } else {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0 })
      }, 10)
    })
  }

}

const router = createRouter({

  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior

})

export default router