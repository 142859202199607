import { createApp } from 'vue'
import App from './App.vue'
import router from './route/index'
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App)

new WaveUI(app, {
  // Some Wave UI options.
})

app.use(VueAxios, axios)
app.use(router)
app.mount('#app')