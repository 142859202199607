<template>

  <header :class="{ 'active': activeItem.status }">
    <div>
      <a href="https://onecode-web.com">
        <img src="./assets/image/icon/onecode.svg" alt="one code">
      </a>      
    </div>
    <nav class="pc">
      <ul>
        <li>
          <router-link :to="{ name: 'Home' }">
            <span>HOME</span>
            ホーム
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'About' }">
            <span>ABOUT</span>
            事業内容
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Contact' }">
            <span>CONTACT</span>
            お問い合わせ
          </router-link>
        </li>
      </ul>
    </nav>
    <button class="sp" @click="openMenu" :class="activeItem.state">
      <span></span>
    </button>
    <nav class="sp">
    <h1>
      <img src="./assets/image/icon/onecode-w.svg" alt="one code">
    </h1>
      <ul>
        <li @click="closeMenu">
          <router-link :to="{ name: 'Home' }">
            <span>HOME</span>
            ホーム
          </router-link>
        </li>
        <li @click="closeMenu">
          <router-link :to="{ name: 'About' }">
            <span>ABOUT</span>
            事業内容
          </router-link>
        </li>
        <li @click="closeMenu">
          <router-link :to="{ name: 'Contact' }">
            <span>CONTACT</span>
            お問い合わせ
          </router-link>
        </li>
      </ul>
    </nav>
  </header>

  <main>
    <w-app>
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    </w-app>
  </main>

  <footer>
    <nav>
      <div>
        <img src="./assets/image/icon/onecode-bl.svg" alt="one code">
      </div>
      <div>
        <dl>
          <dt>SITE MAP</dt>
          <dd>
            <router-link :to="{ name: 'Home' }">
              ホーム
            </router-link>
          </dd>
          <dd>
            <router-link :to="{ name: 'About' }">
              事業内容
            </router-link>
          </dd>
          <dd>
            <router-link :to="{ name: 'Contact' }">
              お問い合わせ
            </router-link>
          </dd>
        </dl>
        <dl>
          <dt>CONTACT</dt>
          <dd>
            info(at-mark)onecode-web.com
            <span>※ (at-mark)を@に置き換えてください。</span>
          </dd>
        </dl>
      </div>
    </nav>
    <div>
      <small>
        &copy; one code
      </small>
      <!-- <ul>
        <li>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src="./assets/image/icon/twitter.svg" alt="twitter">
          </a>
        </li>
        <li>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src="./assets/image/icon/facebook.svg" alt="facebook">
          </a>
        </li>
      </ul> -->
    </div>
  </footer>

</template>

<script>
import { reactive, provide } from 'vue'
import store from '@/store'

export default {
  setup() {

    provide('store', store)

    let activeItem = reactive({
      openMenu: "false",
      state: "",
      status: false
    })

    const openMenu = () => {
      if(activeItem.openMenu) {
        activeItem.openMenu = !activeItem.openMenu
        activeItem.state = "open"
        activeItem.status = !activeItem.status
      } else {
        activeItem.openMenu = !activeItem.openMenu
        activeItem.state = "close"
        activeItem.status = !activeItem.status
      }
    }

    const closeMenu = () => {
      activeItem.openMenu = !activeItem.openMenu
      activeItem.state = "close"
      activeItem.status = !activeItem.status    
    }

    return { openMenu, activeItem, closeMenu };
  }

}
</script>

<style lang="scss">

.route-enter-active {
  transition: opacity .3s ease-in-out ;
}

.route-enter-from,
.route-leave-to {
  opacity: 0;
}

.w-app {
  min-height: unset !important;
}

header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 160px;
  padding: 0 70px;

  nav.pc {
    width: 100%;
    max-width: 350px;
    margin: 0 0 0 auto;

    ul {
      display: flex;
      justify-content: space-between;

    li {
      a {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 13px 5px 5px;
        font-size: 12px;
        align-items: center;

          span {
            font-size: 20px;
            font-family: 'Josefin Sans', sans-serif;
          }

          &::before,
          &::after {
            position: absolute;
            top: 0;
            left: calc(50% - 4px);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $green;
            opacity: 0;
            transition: .3s;
            content: '';
          }

          &:hover::before,
          &:hover::after,
          &.router-link-exact-active::before,
          &.router-link-exact-active::after {
            position: absolute;
            top: 0;
            left: calc(50% - 4px);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $green;
            opacity: 1;
            content: '';
          }

          &:hover::after,
          &.router-link-exact-active::after {
            background: unset;
            border: 1px solid $green;
            animation: ripples 1s linear infinite;
          }

          @keyframes ripples {
            from {
              transform: scale(1);
              opacity: 1;
            }
            to {
              transform: scale(2.5);
              opacity: 0;
            }
          }
        }
      }
    }
  }

  button {
    position: relative;
    z-index: 1100;
    width: 40px;
    margin: 0 0 0 auto;
    padding: 5px;

    span {
      position: relative;
      display: block;
      width: 100%;
      height: 3px;
      margin: 10px 0;
      background-color: $black;

      &::before {
        @include menu-button();
        top: -7px;
      }

      &::after {
        @include menu-button();
        bottom: -7px;
      }

    }

    &.open {
      span {
        animation: openMenu03 .8s ease-in-out forwards;

        &::before {
          animation: openMenu01 .8s ease-in-out forwards;
        }

        &::after {
          animation: openMenu02 .8s ease-in-out forwards;
        }
      }
    }

    &.close {
      span {
        animation: closeMenu03 .8s ease-in-out forwards;

        &::before {
          animation: closeMenu01 .8s ease-in-out forwards;
        }

        &::after {
          animation: closeMenu02 .8s ease-in-out forwards;
        }
      }
    }

    @keyframes openMenu01 {
      0% {
        top: -7px;
      }
      40% {
        top: 0;
        background-color: $white;
      }
      60% {
        top: 0;
        transform: rotate(0);
        transform-origin: center;
        background-color: $white;
      }
      100% {
        top: 0;
        transform: rotate(-80deg);
        background-color: $white;
      }
    }

    @keyframes closeMenu01 {
      0% {
        top: 0;
        transform: rotate(-80deg);
        background-color: $white;
      }
      40% {
        top: 0;
        transform: rotate(0);
        transform-origin: center;
        background-color: $black;
      }
      60% {
        top: 0;
        background-color: $black;
      }
      100% {
        top: -7px;
        background-color: $black;
      }
    }

    @keyframes openMenu03 {
      0% {
        transform: rotate(0);
      }
      40% {
        transform: rotate(0);
        background-color: $white;
      }
      60% {
        transform: rotate(0);
        background-color: $white;
      }
      100% {
        transform: rotate(40deg);
        background-color: $white;
      }
    }

    @keyframes closeMenu03 {
      0% {
        transform: rotate(40deg);
        background-color: $white;
      }
      40% {
        transform: rotate(0);
        background-color: $black;
      }
      60% {
        transform: rotate(0);
        background-color: $black;
      }
      100% {
        transform: rotate(0);
        background-color: $black;
      }
    }

    @keyframes openMenu02 {
      0% {
        bottom: -7px;
      }
      40% {
        bottom: 0;
        background-color: $white;
      }
      60% {
        bottom: 0;
        opacity: 0;
      }
      100% {
        bottom: 0;
        opacity: 0;
      }
    }

    @keyframes closeMenu02 {
      0% {
        bottom: 0;
        opacity: 0;
      }
      40% {
        bottom: 0;
      }
      60% {
        bottom: 0;
        opacity: 1;
      }
      100% {
        bottom: -7px;
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 1082px) {
    padding: 0 50px;
  }
}

footer {
  width: 100%;
  margin-top: 0em!important;

  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 125px 130px;
    border-bottom: 1px solid rgba(17, 17, 17, 0.30);

    div:last-child {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 430px;

      dl {
        dt {
          font-size: 18px;
          font-family: 'Josefin Sans', sans-serif;
        }

        dd {
          margin: 16px 0 0 0;
          font-size: 14px;

          span {
            display: block;
            margin: 5px 0 0 0;
            font-size: 10px;
          }

          a {
            display: inline-block;
            padding: 3px;
            transition: .3s;

            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 30px 130px;

    small {
      color: rgba(17, 17, 17, 0.30);
    }

    ul {
      display: flex;

      li {
        width: 40px;
        height: 40px;
        transition: .3s;

        img {
          width: 100%;
        }

        & + li {
          margin: 0 0 0 20px;
        }

        &:hover {
          opacity: .9;
        }
      }
    }
  }

  @media screen and (max-width: 1082px) {

    nav {
      width: calc(100% - 50px);
      padding: 125px 50px;
    }
  }
}

@media screen and (max-width: 767px) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: auto;
    padding: 20px 20px;
    background-color: rgba(255, 255, 255, 0.80);

    > div {
      max-width: 143px;

      img {
        width: 100%;
      }
    }

      nav:last-child {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1050;
        width: 100%;
        height: 100vh;
        padding: 20px 20px;
        background-color: $green;
        color: $white;
        opacity: 0;
        visibility: hidden;
        transition: .4s;

        h1 {
          max-width: 143px;
    
          img {
            width: 100%;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 80%;
          text-align: center;

          li {
            margin: 40px 0 0 0;

            a {
              display: flex;
              flex-direction: column;
              align-items: center;
              color: $white;
              font-size: 14px;

              span {
                color: $white;
                font-size: 20px;
                font-family: 'Josefin Sans', sans-serif;
              }
            }
          }
        }
      }

    &.active {

      nav:last-child {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  footer {

    nav {
      flex-direction: column;
      align-items: center;
      padding: 50px 0;

      div:last-child {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 60px 0 0 0;

        dl + dl {
          margin: 40px 0 0 0;
        }
      }
    }

    > div {
      padding: 30px 25px;
    }
  }
}

</style>