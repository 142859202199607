import { reactive, readonly } from 'vue'

const state = reactive({
  contact: false
})

const methods = {
  screenTransition() {
    state.contact = true;
  }
}

export default {
  state: readonly(state), 
  methods
}