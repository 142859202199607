export default function Observer() {

  const targetElements = document.querySelectorAll('.target');
  const options = {
    root: null,
    // rootMargin: '-160px',
    threshold: 0.28
  }

  const observer = new IntersectionObserver(entries => {
    for(const e of entries) {
      if(e.isIntersecting) {
        activateIndex(e.target);
      }
    }
  }, options);
  
  targetElements.forEach(target => {
    observer.observe(target);
  })

  function activateIndex(element) {
    element.classList.add('show');
  }
  
}