export default function validators() {
  const emailReg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;

  const isEmpty = (name, fieldValue) => {
    return !fieldValue ? name + "を入力してください" : "";
  };

  const maxLength = (max, fieldValue) => {
    return fieldValue.length > max ? max + "文字以内で入力してください" : "";
  }

  const isEmail = (fieldValue) => {
    return !emailReg.test(fieldValue) ? "メールアドレスを正しく入力してください" : "";
  }

  const isSelect = (fieldValue) => {
    return !fieldValue ? "お問い合わせ内容を選択してください" : "";
  }

  return { isEmpty, maxLength, isEmail, isSelect }
}