<template>
  <div class="carousel">
    <slot :currentSlide="currentSlide"/>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  setup() {
    const currentSlide = ref(1);

    const slide = setInterval(() => {
      currentSlide.value += 1;

      if(currentSlide.value > 2) {
        currentSlide.value = 1;
      }
    }, 6000);
    slide

    return { currentSlide }
  }
}
</script>

<style lang="scss">
.carousel {
  position: relative;
}
</style>