<template>

    <section class="contact">
      <h1>お問い合わせ</h1>
      <p>
        Web制作や動画編集など幅広くお受けします。<br>
        『コーディングだけ』や、『動きの部分だけ』など<br>
        部分的なお手伝いもさせていただいております。
      </p>
      <form @submit.prevent="submit" novalidate autocomplete="off">
        <dl>
          <div>
            <dt>
              <span>お問い合わせ内容</span>
            </dt>
            <dd class="radios">
              <w-radios
                name="selection"
                v-model="inputValue.values.selection"
                @click="handleInputChange"
                :items="radioItems"
                color="teal"
              >
              </w-radios>
              <transition name="show">
                <small v-if="errors.selection">
                  {{ errors.selection }}
                </small>
              </transition>
            </dd>
          </div>
          <div>
            <dt>
              <span>お名前</span>
            </dt>
            <dd>
              <input 
                type="text"
                name="yourName"
                :value="inputValue.values.yourName"
                placeholder="例）田中太朗"
                @input="handleInputChange"
                :class="{'error': errors.yourName}"
              />
              <transition name="show">
                <small v-if="errors.yourName">
                  {{ errors.yourName }}
                </small>
              </transition>
            </dd>
          </div>
          <div>
            <dt>
              <span>ふりがな</span>
            </dt>
            <dd>
              <input 
                type="text"
                name="kana"
                :value="inputValue.values.kana"
                placeholder="例）たなかたろう"
                @input="handleInputChange"
                :class="{'error': errors.kana}"
              />
              <transition name="show">
                <small v-if="errors.kana">
                  {{ errors.kana }}
                </small>
              </transition>
            </dd>
          </div>
          <div>
            <dt>
              <span>メールアドレス</span>
            </dt>
            <dd>
              <input 
                type="text"
                name="email"
                :value="inputValue.values.email"
                placeholder="例）example@onecode-web.com"
                @input="handleInputChange"
                class="contact01"
                :class="{'error': errors.email}"
              />
              <transition name="show">
                <small v-if="errors.email">
                  {{ errors.email }}
                </small>
              </transition>
            </dd>
          </div>
          <div>
            <dt>
              <span>お問い合わせ内容の詳細</span>
            </dt>
            <dd>
              <textarea
                name="contain"
                :value="inputValue.values.contain"
                placeholder="例）webサイト制作の依頼"
                @input="handleInputChange"
                :class="{'error': errors.contain}"
              />
              <transition name="show">
                <small v-if="errors.contain">
                  {{ errors.contain }}
                </small>
              </transition>
            </dd>
          </div>  
          <div>
            <dt>
              会社名・屋号名
            </dt>
            <dd>
              <input
                type="text"
                name="companyName"
                :value="inputValue.values.companyName"
                placeholder="例）one code"
                @input="handleInputChange"
                :class="{'error': errors.companyName}"
                class="contact01"
              />
              <transition name="show">
                <small v-if="errors.companyName">
                  {{ errors.companyName }}
                </small>
              </transition>
            </dd>
          </div>
        </dl>
        <p class="contact-privacy">
          <span>※個人情報の保護について</span>
          当ウェブサイトのお問い合わせフォームをご利用いただいた場合に、ユーザーの個人情報の取扱いを行いますが、 その個人情報を、ユーザーの同意なしに第三者に開示することはありません。
        </p>
        <p :class="{'show': errorFromPHP.error}">
          {{ errorFromPHP.message }}
        </p>
        <button type="submit">
          送信する
        </button>
      </form>

    </section>
</template>

<script>
import { reactive, onMounted, inject } from 'vue'
import validators from '../utils/validators'
import { useRouter } from 'vue-router'
import axios from 'axios'

export default {

  setup() {
    const store = inject('store')
    const router = useRouter()

    const { isEmpty, maxLength, isEmail, isSelect } = validators();

    const inputValue = reactive({
      values: {
        yourName: "",
        kana: "",
        email: "",
        contain: "",
        companyName: "",
        selection: ""
      },
    })
    const errors = reactive({});
    const errorFromPHP = reactive({
      error: false,
      message: ""
    })

    const radioItems = [
      { label: '案件のご相談', value: '案件のご相談' },
      { label: 'その他のお問い合わせ', value: 'その他' },
    ]

    onMounted(() => {

      inputValue.values = { ...inputValue.values, token: '31um8czdqfab' }

    })

    const send = async (data) => {
      await axios.post('https://onecode-web.com/form/test.php', data)
      .then((res) => {
        if(res.data.state === "000") {
          store.methods.screenTransition();
          router.push({ name: 'Thanks' })
        } else if(res.data.state === "111") {
          errorFromPHP.error = true
          errorFromPHP.message = "申し訳ありません、通信に失敗しました。"
        } else if(res.data.state === "222") {
          errorFromPHP.error = true
          errorFromPHP.message = "入力内容をもう一度、ご確認ください。"
        } else if(res.data.state === "333") {
          errorFromPHP.error = true
          errorFromPHP.message = "onecodeのフォームから送信してください。"
        }
      })
    }

    const handleInputChange = e => {
      const { name, value } = e.target
      inputValue.values = {...inputValue.values, [name]: value}
      
      validate({ [name]: value })
    }

    const validate = (fieldvalue = inputValue) => {
      if('yourName' in fieldvalue) {
        errors.yourName = !fieldvalue.yourName ? isEmpty("お名前", fieldvalue.yourName) : maxLength(20, fieldvalue.yourName)
      }

      if('kana' in fieldvalue) {
        errors.kana = !fieldvalue.kana ? isEmpty("ふりがな", fieldvalue.kana) : maxLength(20, fieldvalue.kana)
      }

      if('email' in fieldvalue) {
        errors.email = !fieldvalue.email ? isEmpty("メールアドレス", fieldvalue.email) : isEmail(fieldvalue.email)
      }

      if('contain' in fieldvalue) {
        errors.contain = !fieldvalue.contain ? isEmpty("お問い合わせ内容", fieldvalue.contain) : maxLength(1000, fieldvalue.contain)
      }

      if('companyName' in fieldvalue) {
        errors.companyName = !fieldvalue.companyName ? maxLength(50, fieldvalue.companyName) : ""
      }

      if('selection' in fieldvalue) {
        errors.selection = !fieldvalue.selection ? isSelect(fieldvalue.selection) : ""
      }

      return Object.values(errors).every(errors => errors === "")
    }

    const submit = () => {

      if(validate(inputValue.values)) {
        send(inputValue.values)
      }

    }

    return { 
      submit, 
      inputValue, 
      radioItems,
      errors,
      errorFromPHP,
      // validate
      handleInputChange
    }
  },
}
</script>

<style lang="scss">

.show-enter-active,
.show-leave-active {
  transition: opacity .1s ease-in-out ;
}

.show-enter-from,
.show-leave-to {
  opacity: 0;
}

.contact {
  @include lowerpage-title('CONTACT');

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    margin: 15px auto 0;
    padding: 0 50px;

    dl{
      width: 100%;

      > div {
        display: flex;
        width: 100%;
        margin: 70px 0 0 0;

        dt {
          width: 100%;
          max-width: 280px;
          padding: 10px 0 0 0;
          margin: 0 100px 0 0;

          span {
            position: relative;
            font-weight: bold;
      
            &::after {
              position: absolute;
              top: 50%;
              right: -60px;
              transform: translateY(-50%);
              padding: 5px 10px;
              background-color: $green;
              color: $white;
              font-size: 10px;
              content: '必須';
            }
          }
        }

        dd {
          width: 100%;
          max-width: calc(100% - 350px);

          input {
            display: block;
            width: 50%;
            height: 40px;
            padding: 0 15px;
            border: 1px solid rgba(17, 17, 17, 0.60);
            transition: .3s;

            &.error {
              border: 1px solid #CE3737;

              &::placeholder {
                color: rgba(206, 55, 55, 0.8);
              }
            }

            &.contact01 {
              width: 100%;
            }
          }

          textarea {
            width: 100%;
            height: 160px;
            padding: 10px 15px;
            border: 1px solid rgba(17, 17, 17, 0.60);
            resize: none;

            &.error {
              border: 1px solid #CE3737;

              &::placeholder {
                color: rgba(206, 55, 55, 0.8);
              }
            }
          }

          small {
            color: #CE3737;
          }

          &.radios {
            > div.w-radios--column {
              flex-direction: row;
              width: 100%;

              .w-radio:nth-of-type(2) {
                margin: 0 0 0 70px;
              }
            }
          }
        }
      }
    }

    button {
      margin: 75px 0 0 0;
      width: 400px;
      padding: 15px 0;
      background-color: $green;
      border: 2px solid $green;
      border-radius: 20px;
      color: $white;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      transition: .3s;

      &:hover {
        background-color: $white;
        color: $green;
      }
    }

    @media screen and (max-width: 1082px) {
      dl {
        > div {
          dt {
            margin: 0 20px 0 0;
          }

          dd {
            max-width: unset;
          }

          dd.radios {
            > div.w-radios--column {
              white-space: nowrap;

              .w-radio:nth-of-type(2) {
                margin: 0 0 0 20px;
              }
            }
          }
        }
      }
    }

    > p.contact-privacy {
      margin: 50px 0 0 0;
      font-size: 14px;
      text-align: left;
      line-height: 1.4;

      span {
        display: block;
        margin: 0 0 10px 0;
      }
    }

    > p:last-child {
      color: #CE3737;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 767px) {
  .contact {
    margin: 140px auto 0;

    form {
      margin: 35px 0 0 0;
      padding: 0 20px;

      dl {
        > div {
          flex-direction: column;
          margin: 45px 0 0 0;

          dd {
            font-size: 14px;

            input {
              width: 100%;
              margin: 25px 0 0 0;

              &::placeholder {
                font-size: 14px;
              }
            }

            textarea {
              margin: 25px 0 0 0;

              &::placeholder {
                font-size: 14px;
              }
            }

            &.radios {
              margin: 25px 0 0 0;
              > div.w-radios--column {
                flex-direction: column;

                .w-radio:nth-of-type(2) {
                  margin: 20px 0 0 0;
                }
              }
            }
          }
        }
      }

      button {
        margin: 80px 0;
        width: calc(100% - 40px);
      }
    }
  }
}
</style>