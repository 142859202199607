<template>
  <section>
    <h2>
      お見積もりやご相談など<br>
      お気軽にお問い合わせください。
    </h2>
    <router-link :to="{ name: 'Contact' }">
      お問い合わせ
    </router-link>
  </section>
</template>

<style lang="scss" scoped>
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  margin: 170px 0 0 0;
  background-color: $green;

  h2 {
    width: 100%;
    color: $white;
    font-size: 24px;
    text-align: center;
    font-family: 'Noto Sans JP_b';
    font-weight: 800;
    line-height: 1.8;
  }

  a {
    margin: 30px 0 0 0;
    color: $white;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 30px 13px;
    border: 2px solid $white;
    border-radius: 20px;
    transition: .3s;

    &:hover {
      background-color: $white;
      color: $green;
    }
  }
}

@media screen and (max-width: 767px) {
  section {
    margin: 80px 0 0 0;

    h2 {
      font-size: 16px;
    }
  
    a {
      font-size: 14px;
    }
  }
}
</style>